import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Spinner,
  Flex,
  Button,
  HStack,
} from '@chakra-ui/react';
import DashboardLayout from '../components/DashboardLayout';
import api from '../services/api'; // Assumindo que você já tem o Axios configurado em um arquivo separado

type User = {
  name: string;
  username: string;
  cpf: string;
  email: string;
};

type Withdraw = {
  id: number;
  amount: number;
  pixKey: string;
  status: "PENDING" | "COMPLETED" | "CANCELLED";
  createdAt: string;
  updatedAt: string;
  user: User;
};

const Withdraw = () => {
  const [withdraws, setWithdraws] = useState<Withdraw[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchWithdraws = async (page: number) => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/withdrawRequests`, {
        params: {
          skip: 0,
          take: 10,
          cpf: "376.735.700-33",  // Substitua pelo CPF desejad
        },
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlnZnNhbnRvc0Bob3RtYWlsLmNvbSIsInBhc3N3b3JkIjoiaWdmc2FudG9zIiwiaWQiOjEwLCJpc0FkbWluIjpudWxsLCJpYXQiOjE3MjQ5NDI2NzUsImV4cCI6MTcyNTAyOTA3NX0.cdBNxG-tfzm1BSil5zqN5eOg0HJnWyUN8d3qxVKZq0k`,
        },
      });
      console.log(response)
      setWithdraws(response.data.data); // Supondo que os dados venham em um array 'withdraws'
      setTotalPages(response.data.totalPages); // Supondo que 'totalPages' vem na resposta
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar withdraws:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdraws(currentPage);
  }, [currentPage]);

  const getStatus = (withdraw: Withdraw) => {
    if (withdraw.status === 'COMPLETED') {
      return <Box bg="green.500" p={2} borderRadius="md" textAlign="center">COMPLETADO</Box>;
    } else if (withdraw.status === 'PENDING') {
      return <Box bg="yellow.500" p={2} borderRadius="md" textAlign="center">PENDENTE</Box>;
    } else {
      return <Box bg="red.500" p={2} borderRadius="md" textAlign="center">CANCELADO</Box>;
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const sectionBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'white');
  useEffect(()=> {
    console.log('test', withdraws)
  },[])
  return (
    <DashboardLayout>
      <Box p={8} bg="gray.50" minH="100vh">
        <Text fontSize="2xl" fontWeight="bold" mb={6}>
          Withdraw
        </Text>

        <Box bg={sectionBgColor} p={6} borderRadius="lg" boxShadow="lg">
          {loading ? (
            <Flex justify="center" align="center" minH="200px">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr>
                    <Th>ID</Th>
                    <Th>Valor</Th>
                    <Th>Data</Th>
                    <Th>Status</Th>
                    <Th>Usuário</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {withdraws?.map((withdraw) => (
                    <Tr key={withdraw.id}>
                      <Td>{withdraw.id}</Td>
                      <Td>R${withdraw.amount.toFixed(2)}</Td>
                      <Td>{new Date(withdraw.createdAt).toLocaleDateString()}</Td>
                      <Td>{getStatus(withdraw)}</Td>
                      <Td>{withdraw.user.name} ({withdraw.user.username})</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <HStack mt={4} justify="center">
                <Button onClick={() => handlePageChange(currentPage - 1)} isDisabled={currentPage === 1}>
                  Anterior
                </Button>
                <Text>{`Página ${currentPage} de ${totalPages}`}</Text>
                <Button onClick={() => handlePageChange(currentPage + 1)} isDisabled={currentPage === totalPages}>
                  Próxima
                </Button>
              </HStack>
            </>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Withdraw;
