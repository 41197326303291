import React, { useState } from 'react';
import {
  Box,
  VStack,
  Link,
  Icon,
  IconButton,
  Flex,
  Text,
  useMediaQuery,
  Collapse,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaBars, FaUser, FaUsers, FaTimes, FaPlay, FaHackerrank, FaGuitar, FaTrophy, FaBook } from 'react-icons/fa';
import { BsPlay } from 'react-icons/bs';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar o menu expansível

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (isMobile) {
    // Layout para dispositivos móveis
    return (
      <Box
        zIndex={999}
        as="nav"
        bg="#17161A"
        color="white"
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        p={4}
        pt={8}
        boxShadow="lg"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link as={RouterLink} to="/rank" display="flex" flexDir="column" alignItems="center">
          <Icon as={FaTrophy} boxSize={6} />
          <Text fontSize="xs">Ranking</Text>
        </Link>
        <Link as={RouterLink} to="/play" position="relative" mt="-30px">
          <Flex
            bg="#0e0e10"
            border="solid 1px #ffcc00"
            color="#ffcc00"
            borderRadius="full"
            boxSize="60px"
            justify="center"
            align="center"
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.3)"
          >
            <Icon as={BsPlay} boxSize={8} />
          </Flex>
        </Link>

        {/* Botão de Ver Mais Opções para mobile */}
        <IconButton
          icon={<FaBars />}
          onClick={toggleMenu}
          color="white"
          variant="ghost"
          aria-label="Ver Mais Opções"
          fontSize="24px"
          boxSize="60px"
          borderRadius="full"
          _hover={{ bg: "gray.700" }}
        />

        {/* Menu expansível com mais opções */}
        <Collapse in={isMenuOpen} animateOpacity>
          <Box
            position="absolute"
            bottom="80px"
            right="20px"
            bg="gray.800"
            p={4}
            borderRadius="md"
            boxShadow="lg"
          >
            <VStack spacing={4} align="stretch">
              <Link as={RouterLink} to="/profile">
                <Flex align="center">
                  <Icon as={FaUser} boxSize={4} mr={2} />
                  <Text>Perfil</Text>
                </Flex>
              </Link>

              <Link as={RouterLink} to="/affiliation">
                <Flex align="center">
                  <Icon as={FaUsers} boxSize={4} mr={2} />
                  <Text>Afiliação</Text>
                </Flex>
              </Link>

              <Link as={RouterLink} to="/rules">
                <Flex align="center">
                  <Icon as={FaBook} boxSize={4} mr={2} />
                  <Text>Regras</Text>
                </Flex>
              </Link>
            </VStack>
          </Box>
        </Collapse>
      </Box>
    );
  }

  // Layout para desktop (permite abrir e fechar o sidebar)
  return (
    <Box display="flex" bg="#17161A">
      <Box
        as="nav"
        bg="#17161A"
        color="white"
        w={isOpen ? '250px' : '70px'}
        minH="100vh"
        borderRight="1px solid"
        borderColor="#ffaa00"
        p={4}
        boxShadow="lg"
        transition="width 0.3s ease"
        pos="fixed"
        display="flex"
        flexDir="column"
        justifyContent="space-between"
      >
        {/* Botão de abrir/fechar */}
        <Flex justifyContent={isOpen ? 'flex-end' : 'center'}>
          <IconButton
            icon={isOpen ? <FaTimes /> : <FaBars />}
            onClick={toggleSidebar}
            colorScheme="teal"
            variant="ghost"
            aria-label="Toggle Sidebar"
            mb={8}
            color="white"
            _hover={{ transform: 'scale(1.1)' }}
            transition="transform 0.3s"
          />
        </Flex>

        {/* Links de navegação */}
        <VStack spacing={6} align={isOpen ? "flex-start" : "center"} mt={4}>
          <Link
            as={RouterLink}
            to="/play"
            fontWeight="bold"
            fontSize={isOpen ? "lg" : "md"}
            display="flex"
            alignItems="center"
            p={isOpen ? 3 : 2}
            borderRadius="md"
            _hover={{ bg: 'gray.700', color: '#ffaa00' }}
            transition="background-color 0.3s"
          >
            <Icon as={FaPlay} boxSize={6} mr={isOpen ? 3 : 0} />
            {isOpen && <Text>Jogar</Text>}
          </Link>

          <Link
            as={RouterLink}
            to="/rank"
            fontWeight="bold"
            fontSize={isOpen ? "lg" : "md"}
            display="flex"
            alignItems="center"
            p={isOpen ? 3 : 2}
            borderRadius="md"
            _hover={{ bg: 'gray.700', color: '#ffaa00' }}
            transition="background-color 0.3s"
          >
            <Icon as={FaTrophy} boxSize={6} mr={isOpen ? 3 : 0} />
            {isOpen && <Text>Ranking</Text>}
          </Link>

          <Link
            as={RouterLink}
            to="/profile"
            fontWeight="bold"
            fontSize={isOpen ? "lg" : "md"}
            display="flex"
            alignItems="center"
            p={isOpen ? 3 : 2}
            borderRadius="md"
            _hover={{ bg: 'gray.700', color: '#ffaa00' }}
            transition="background-color 0.3s"
          >
            <Icon as={FaUser} boxSize={6} mr={isOpen ? 3 : 0} />
            {isOpen && <Text>Perfil</Text>}
          </Link>

          <Link
            as={RouterLink}
            to="/affiliation"
            fontWeight="bold"
            fontSize={isOpen ? "lg" : "md"}
            display="flex"
            alignItems="center"
            p={isOpen ? 3 : 2}
            borderRadius="md"
            _hover={{ bg: 'gray.700', color: '#ffaa00' }}
            transition="background-color 0.3s"
          >
            <Icon as={FaUsers} boxSize={6} mr={isOpen ? 3 : 0} />
            {isOpen && <Text>Afiliação</Text>}
          </Link>

          <Link
            as={RouterLink}
            to="/rules"
            fontWeight="bold"
            fontSize={isOpen ? "lg" : "md"}
            display="flex"
            alignItems="center"
            p={isOpen ? 3 : 2}
            borderRadius="md"
            _hover={{ bg: 'gray.700', color: '#ffaa00' }}
            transition="background-color 0.3s"
          >
            <Icon as={FaBook} boxSize={6} mr={isOpen ? 3 : 0} />
            {isOpen && <Text>Regras</Text>}
          </Link>
        </VStack>

        {/* Footer do Sidebar */}
        {isOpen && (
          <Text fontSize="sm" color="gray.500" mt="auto" textAlign="center">
            © 2024 Guitar Money
          </Text>
        )}
      </Box>

      {/* Conteúdo principal */}
      <Box flex="1" ml={isOpen ? '250px' : '70px'} p={4} transition="margin-left 0.3s ease">
        {/* Conteúdo principal da página */}
      </Box>
    </Box>
  );
};

export default Sidebar;
