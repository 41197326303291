import axios from 'axios';

const api = axios.create({
  // https://abstractor.serveo.net
  // baseURL: 'https://abstractor-1pgz.onrender.com',
  baseURL: 'https://e366-138-94-7-73.ngrok-free.app'
});

// Interceptor para adicionar o apikey e o Bearer token se o usuário estiver logado
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  
  // Adiciona o apikey no header
  config.headers['apikey'] = 'e0368cd8c2a80bc328251af64e4f1a8b37faca281b3e22e59001cfc047ad5fc9611b584fb7a1aa8a82b30d37e03853bb8d6c1dfe84d6ef66a928bab1e296102854b6c404b1f4f1186c26c7a9c3d2c5de4c30db648535b6c39e1f17e23b75794e4da72c45ee6f78de087729549ec9d94952ab7f5e82eba22f7cd7d2b01128c6cdbe5110d32d46a290934dfc86de19e0cd2c603c73f9573206ce52a6f1a7c49dc5';

  // Se o token estiver presente no localStorage, adiciona o header Authorization
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
