import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout';
import styles from './Afiliation.module.scss';
import { Box, Button, Input, useToast } from '@chakra-ui/react';
import { FaCopy, FaUsers, FaWhatsapp } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useUser } from '../../contexts/userContext';
import { formatCurrency } from '../../utils/utils';
import api from '../../services/api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Afiliation = () => {
  const toast = useToast();

  const formatDateTime = (date: Date) => {
    return date.toISOString().slice(0, 16);
  };

  const currentDate = new Date();
  const initialDate = new Date();
  initialDate.setDate(currentDate.getDate() - 10);

  const [dataInicial, setDataInicial] = useState(formatDateTime(initialDate));
  const [dataFinal, setDataFinal] = useState(formatDateTime(currentDate));
  const [chartData, setChartData] = useState(null);
  const [affiliates, setAffiliates] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useUser();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % 3);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleFetchData = async () => {
    try {
      const response = await api.get('/user/afiliated', {
        params: {
          from: dataInicial,
          to: dataFinal,
        },
      });
      setAffiliates(response.data.count);

      const newData = {
        labels: response.data.count.map(item => item.date),
        datasets: [
          {
            label: 'Afiliações',
            data: response.data.count.map(item => item.count),
            borderColor: '#FFA500',
            backgroundColor: 'rgba(255, 165, 0, 0.2)',
            tension: 0.4,
          },
        ],
      };
      setChartData(newData);
    } catch (error) {
      console.error('Erro ao buscar os dados da API:', error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const affiliateLink = user?.indicationId ? `https://guitarrinha.com/register?indicationId=${user.indicationId}` : '#';

  const handleCopyLink = () => {
    const message = `Venha tocar uma pra mim! ${affiliateLink}`;
    navigator.clipboard.writeText(message)
      .then(() => {
        toast({
          title: 'Link copiado!',
          description: 'A mensagem foi copiada com sucesso.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(err => console.error('Erro ao copiar o link: ', err));
  };

  const sendWhatsAppInvite = () => {
    const message = `Venha tocar uma pra mim! ${affiliateLink}`;
    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <DashboardLayout>
      <div className={styles['steps-afiliate']}>
        <ul>
          <StepItem step={1} isActive={activeStep === 0} description="Peque o seu link personalizado e indique para os seus colegas e amigos." />
          <StepItem step={2} isActive={activeStep === 1} description="Receba 50% do valor que o seu amigo pagar na mensalidade." />
          <StepItem step={3} isActive={activeStep === 2} description="Você receberá parte da assinatura de pessoas indicadas pelo seu amigo." />
        </ul>
      </div>

      <section className={styles['background-container']}>
        <div className={styles['container']}>
          <div className={styles['row-infos']}>
            <BalanceCard title="Saldo De Afiliação" amount={user?.balance?.amount !== undefined ? formatCurrency(user.balance.amount) : 'GC$ 0.00'} onCopyLink={handleCopyLink} onSendWhatsAppInvite={sendWhatsAppInvite} />
            <AffiliatesCard title="Total de Afiliados por Nível" totalAffiliates={affiliates.length} />
          </div>

          <div className={styles['row-chart']}>
            <div className={styles['vendas-mes']}>
              <div className={styles['grafico-titulo']}>Vendas por mês</div>

              <div className={styles['form-container']}>
                <Input
                  type="datetime-local"
                  value={dataInicial}
                  onChange={(e) => setDataInicial(e.target.value)}
                  placeholder="Data Inicial"
                  mb={3}
                />
                <Input
                  type="datetime-local"
                  value={dataFinal}
                  onChange={(e) => setDataFinal(e.target.value)}
                  placeholder="Data Final"
                  mb={3}
                />
                <Button onClick={handleFetchData} colorScheme="orange" mb={3}>
                  Buscar Dados
                </Button>
              </div>

              {chartData ? (
                <Line data={chartData} options={{ scales: { y: { beginAtZero: true } } }} />
              ) : (
                <p>Nenhum dado disponível para o período selecionado.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

const BalanceCard = ({ title, amount, onCopyLink, onSendWhatsAppInvite }: { title: string; amount: string; onCopyLink: () => void; onSendWhatsAppInvite: () => void }) => (
  <div className={styles['balance-card']}>
    <div className={styles['card-titulo']}>{title}</div>
    <div className={styles['nivel-item']}>
      <p>{amount}</p>
    </div>
    <Box display="flex" alignItems="center" justifyContent="space-between" className={styles['buttons-container']}>
      <Button onClick={onCopyLink} colorScheme="orange" mt={3} leftIcon={<FaCopy />}>
        Copiar Link
      </Button>
      <Button ml={2} onClick={onSendWhatsAppInvite} colorScheme="whatsapp" mt={3} leftIcon={<FaWhatsapp />}>
        Enviar no WhatsApp
      </Button>
    </Box>
  </div>
);

const AffiliatesCard = ({ title, totalAffiliates }: { title: string; totalAffiliates: number }) => (
  <div className={styles['affiliates-card']}>
    <div className={styles['card-titulo']}>{title}</div>
    <div className={styles['nivel-item']}>
      <FaUsers className={styles['icon']} />
      <p>{totalAffiliates}</p>
    </div>
  </div>
);

const StepItem = ({ step, isActive, description }: { step: number; isActive: boolean; description: string }) => (
  <li className={isActive ? styles['active'] : ''}>
    <div className={styles['step-number']}>{step}</div>
    <div className={styles['step-content']}>
      <h3>Passo {step}</h3>
      <p>{description}</p>
    </div>
  </li>
);

export default Afiliation;
