import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Spinner,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import QRCode from 'react-qr-code';
import CustomButton from '../CustomButton/CustomButton';
import Timer from '../Timer';
import { NumericFormat } from 'react-number-format';

interface AddCreditsModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleGenerateQrCode: (value: number) => void;
  isLoading: boolean;
  qrCode: string | null;
  transactionId: string | null;
  checkPaymentStatus: (transactionId: string) => Promise<string>;
}

const AddCreditsModal: React.FC<AddCreditsModalProps> = ({
  isOpen,
  onClose,
  handleGenerateQrCode,
  isLoading,
  qrCode,
  transactionId,
  checkPaymentStatus,
}) => {
  const [rawValue, setRawValue] = useState<number | null>(null);
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('não pago');
  const toast = useToast();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    const verifyPaymentStatus = async () => {
      if (transactionId) {
        const status = await checkPaymentStatus(transactionId);
        setPaymentStatus(status === 'PAID' ? 'pago' : 'não pago');

        if (status === 'PAID' && interval) {
          clearInterval(interval);
          toast({
            title: 'Pagamento confirmado!',
            description: 'Obrigado pelo pagamento, você já pode usar seus créditos.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onClose();  // Fechar o modal quando o pagamento for confirmado
        }
      }
    };

    if (isQrCodeVisible && transactionId) {
      interval = setInterval(verifyPaymentStatus, 2000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [transactionId, isQrCodeVisible, checkPaymentStatus, onClose, toast]);

  const handleCopyQrCode = () => {
    if (qrCode) {
      navigator.clipboard.writeText(qrCode);
      toast({
        title: 'QR Code copiado!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleGenerate = () => {
    if (rawValue && rawValue > 0) {
      handleGenerateQrCode(rawValue); // Envia o valor em reais
      setIsQrCodeVisible(true);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="#17161A" color="white">
        <ModalHeader>Adicionar Créditos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isQrCodeVisible && qrCode ? (
            <VStack spacing={4}>
              <Text>Escaneie o QR Code para adicionar créditos:</Text>
              <QRCode value={qrCode} size={200} />
              <Text>Transação: {transactionId}</Text>
              <Timer initialTime={60} onExpire={() => console.log('QR Code expirado!')} />
              <CustomButton onClick={handleCopyQrCode}>Copiar QR Code</CustomButton>
              <Text>Status do pagamento: {paymentStatus}</Text>
            </VStack>
          ) : (
            <VStack spacing={4}>
              <Text>Digite o valor que deseja adicionar (R$):</Text>
              <NumericFormat
                value={rawValue !== null ? rawValue.toString() : ''}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  setRawValue(floatValue ? Math.round(floatValue) : null); // Armazena o valor em reais
                }}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                displayType="input"
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={Input}
                placeholder="Digite o valor"
                bg="#252429"
                color="white"
                borderColor="#2D2C32"
                _placeholder={{ color: '#A0AEC0' }}
              />
              {isLoading && <Spinner size="lg" />}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center">
          {!isQrCodeVisible && (
            <CustomButton
              style={{ width: '50%', fontSize: '16px' }}
              onClick={handleGenerate}
              disabled={!rawValue || isLoading}
            >
              Gerar QR Code
            </CustomButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditsModal;
