import React, { useState, useEffect } from 'react';
import { Box, Flex, useDisclosure, Button } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DashboardLayout from '../../components/DashboardLayout';
import { useUser } from '../../contexts/userContext';
import api from '../../services/api';
import logoSweetChild from '../../assets/img/musics/sweet-child-o-mine.jpg';
import bumbum from '../../assets/img/musics/bumbum.png';
import WelcomeBox from './WelcomeBox';
import MusicList from './MusicList';
import AddCreditsModal from '../../components/AddCreditsModal/AddCreditsModal';
import PlayModal from './PlayModal';
import styles from './PlayPage.module.scss';

const musicData = [
  {
    id: 0,
    title: "Sweet Child O' Mine",
    artist: "Guns N' Roses",
    album: "Appetite for Destruction",
    image: logoSweetChild,
    playButton: true,
  },
  {
    id: 1,
    title: "Bum Bum Tam Tam",
    artist: "MC Fiote",
    album: "Bum Bum Tam",
    image: bumbum,
    playButton: true,
  },
  {
    id: 2,
    title: "Música Bloqueada",
    artist: "Artista Bloqueado",
    album: "Álbum Bloqueado",
    image: null,
    playButton: false,
  },
];

const PlayPage = () => {
  const { user, fetchUserData } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = useState('WAITING_PAYMENT');
  const [isSuccess, setIsSuccess] = useState(false);
  const MySwal = withReactContent(Swal);

  const { isOpen: isPlayModalOpen, onOpen: onPlayModalOpen, onClose: onPlayModalClose } = useDisclosure();
  const [selectedSong, setSelectedSong] = useState<{ id: number; title: string; image: string | null } | null>(null);

  // Função para resetar o estado do modal
  const resetModalState = () => {
    setQrCode(null);
    setTransactionId(null);
    setPaymentStatus('WAITING_PAYMENT');
    setIsSuccess(false);
  };

  // Gerar o QR code
  const handleGenerateQrCode = async (value: number) => {
    resetModalState(); // Resetar o estado antes de gerar um novo QR code
    setIsLoading(true);
    try {
      const response = await api.post(
        '/payment/generate/zoldpay',
        {
          name: user?.name,
          email: user?.email,
          cpf: user?.cpf,
          price: value * 100,
        }
      );
      setQrCode(response.data.qrcode);
      setTransactionId(response.data.transactionId);
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao gerar QR Code', error);
      setIsLoading(false);
    }
  };

  const handleCheckTransactionStatus = async (transactionId: string): Promise<string> => {
    setIsLoading(true);
    try {
      const response = await api.get(`/payment/transactionStatus/zoldpay/${transactionId}`);
      const transactionStatus = response.data.status;
      console.log('Status da Transação:', transactionStatus);
      setIsLoading(false);
      return transactionStatus;
    } catch (error) {
      console.error('Erro ao buscar o status da transação:', error);
      setIsLoading(false);
      return 'ERROR';
    }
  };

  const showSuccessAlert = () => {
    MySwal.fire({
      title: 'Sucesso!',
      text: 'Pagamento confirmado com sucesso! Bom jogo e obrigado!',
      icon: 'success',
      background: '#17161A',
      confirmButtonColor: '#FFA500',
      color: '#FFFFFF',
      iconColor: '#00ff00',
    });
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
  
    if (transactionId) {
      interval = setInterval(async () => {
        const status = await handleCheckTransactionStatus(transactionId);
        setPaymentStatus(status);
  
        if (status === 'CONFIRMED' && !isSuccess) {
          setIsSuccess(true);
          clearInterval(interval); // Certifique-se de que o intervalo seja limpo aqui
          onClose(); // Fecha o modal
  
          setTimeout(() => {
            showSuccessAlert(); // Exibe o alerta
            resetModalState(); // Reinicia o estado após exibir o SweetAlert
          }, 500);
        }
      }, 3000);
    }
  
    return () => {
      if (interval) clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    };
  }, [transactionId, isSuccess, onClose]);
  

  const handlePlaySong = (song: { id: number; title: string; image: string | null }) => {
    setSelectedSong(song);
    onPlayModalOpen();
  };

  const handlePlay = async (songId: number, betValue: number) => {
    const body = {
      betValue,
      userId: user?.id,
      status: 'PENDING',
      songId,
    };

    try {
      const response = await api.post('/match/create', body);
      const gameUrl = response.data.gameUrl;
      if (gameUrl) {
        window.open(gameUrl, '_blank');
      } else {
        console.error('gameUrl não encontrado na resposta da API');
      }
    } catch (error) {
      console.error('Erro ao iniciar a música:', error);
    }
  };

  const handleCloseAddCreditsModal = () => {
    onClose();
    fetchUserData();
  };

  const handleClosePlayModal = () => {
    onPlayModalClose();
    fetchUserData();
  };

  return (
    <DashboardLayout>
      <div className={styles['background-container']}>
        <Flex direction={{ base: 'column', md: 'row' }} gap={6} className={styles['content']}>
          <Box flex={{ base: '1', md: '3' }} className={styles['welcome-box']}>
            <WelcomeBox onAddCreditsClick={onOpen} />
          </Box>
          <Box flex={{ base: '1', md: '9' }} className={styles['music-list']}>
            <MusicList musicData={musicData} onPlaySong={handlePlaySong} />
          </Box>
        </Flex>

        <AddCreditsModal
          isOpen={isOpen}
          onClose={handleCloseAddCreditsModal}
          handleGenerateQrCode={handleGenerateQrCode}
          isLoading={isLoading}
          qrCode={qrCode}
          transactionId={transactionId}
          checkPaymentStatus={handleCheckTransactionStatus}
        />
        {selectedSong && (
          <PlayModal
            isOpen={isPlayModalOpen}
            onClose={handleClosePlayModal}
            song={selectedSong}
            onPlay={handlePlay}
          />
        )}

        {isSuccess && <div>Sucesso! Pagamento Aprovado.</div>}
      </div>
    </DashboardLayout>
  );
};

export default PlayPage;
