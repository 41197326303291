import React from 'react';
import AppRoutes from './routes';  // Certifique-se de que o caminho para o arquivo de rotas está correto

function App() {
  return (
    <div className="App">
      <AppRoutes />  {/* Renderizando as rotas */}
    </div>
  );
}

export default App;
