import React, { useState } from 'react';
import { Box, Input, VStack, Text, Checkbox, useToast } from '@chakra-ui/react';
import SquareCard from '../../components/SquareCard/SquareCard';
import './Register.scss';
import logoImg from '../../assets/logo.png';
import CustomButton from '../../components/CustomButton/CustomButton';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api'; // Importe o serviço de API
import { useAuth } from '../../contexts/AuthContext'; // Importe o contexto de autenticação
import InputMask from 'react-input-mask'; // Importa a biblioteca de máscara de inputs
import { Input as ChakraInput } from '@chakra-ui/react'; // Renomeie o Input do Chakra para evitar conflito com o Input do Chakra

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { login } = useAuth(); // Função para salvar o token no contexto
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cpf, setCpf] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Função para extrair o query parameter "indicationId" da URL
  const queryParams = new URLSearchParams(location.search);
  const indicationId = queryParams.get('indicationId');

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      toast({
        title: 'Erro',
        description: 'As senhas não coincidem',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    const userData = {
      email,
      name,
      cpf,
      phone,
      username: email.split('@')[0], // Simples exemplo para gerar o username
      password,
      isAdmin: false,
      isInfluencer: false,
      indicatedId: indicationId || undefined, // Passa o `indicatedId` apenas se estiver presente
    };

    try {
      const endpoint = indicationId ? '/user/create/indication' : '/user/create';
      const response = await api.post(endpoint, userData);

      if (response.status === 201 || 200) {
        const token = response.data.token;
        login(token); // Salva o token e autentica o usuário
        toast({
          title: 'Sucesso',
          description: 'Conta criada com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/play'); // Redireciona para a rota /play
      }
    } catch (error) {
      console.error('Erro ao registrar o usuário:', error);
      toast({
        title: 'Erro',
        description: error.response?.data?.message || 'Erro ao criar conta',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box className="register-page">
      <SquareCard minHeight="auto" className="register-container">
        <Text onClick={() => navigate('/login')} width="100%" align="start" className="texto-voltar">
          <ArrowLeftIcon color="#fff" className="back-button" /> Criação de conta
        </Text>
        <VStack spacing={4} className="form-container">
          <Box mb={4}>
            <img src={logoImg} alt="Logo" className="logo" />
          </Box>
          <ChakraInput
            className="register-input"
            type="text"
            placeholder="Seu nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
            _placeholder={{ color: 'white' }} // Placeholder com cor branca
          />
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          >
            {/* @ts-ignore */}
            {(inputProps) => (
              <ChakraInput
                {...inputProps}
                className="register-input"
                type="text"
                placeholder="Seu CPF"
                style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
                _placeholder={{ color: 'white' }} // Placeholder com cor branca
              />
            )}
          </InputMask>
          <InputMask
            mask="(99) 99999-9999"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          >
            {/* @ts-ignore */}
            {(inputProps) => (
              <ChakraInput
                {...inputProps}
                className="register-input"
                type="tel"
                placeholder="Seu telefone"
                style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
                _placeholder={{ color: 'white' }} // Placeholder com cor branca
              />
            )}
          </InputMask>
          <ChakraInput
            className="register-input"
            type="email"
            placeholder="Seu e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
            _placeholder={{ color: 'white' }} // Placeholder com cor branca
          />
          <ChakraInput
            className="register-input"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
            _placeholder={{ color: 'white' }} // Placeholder com cor branca
          />
          <ChakraInput
            className="register-input"
            type="password"
            placeholder="Confirmar senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ background: 'transparent', color: 'white' }} // Input transparente com cor branca
            _placeholder={{ color: 'white' }} // Placeholder com cor branca
          />
          <Checkbox colorScheme="whiteAlpha" color="white">
            Concordo com todos os <span>termos de privacidade</span>
          </Checkbox>
          <CustomButton className="register-button" onClick={handleRegister} isLoading={isSubmitting}>
            CRIAR
          </CustomButton>
        </VStack>
      </SquareCard>
    </Box>
  );
};

export default Register;
