import { useUser } from "../../contexts/userContext";
import { formatCurrency } from "../../utils/utils";
import { Text, Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Heading } from "@chakra-ui/react";
import { useState } from "react";

const WelcomeBox: React.FC<any> = ({ onAddCreditsClick }) => {
  const { user } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Box bg="#17161A" borderRadius="md" p={4} mb={4} boxShadow="lg">
      <VStack align="start" spacing={3} py={4}> {/* Adicionei py={4} para padding vertical */}
        <Text fontSize="lg" color="#FFFFFF">Bem-vindo(a)</Text>
        <Heading fontSize="2xl" color="#FFFFFF">{user?.name || 'Usuário'}</Heading>
        <Box bg="#1F2937" borderRadius="md" p={3} w="full" py={4}> {/* Adicionei py={4} aqui também */}
          <Text fontSize="md" color="#FFFFFF">Seus Guitar Coins</Text>
          <Heading fontSize="2xl" color="#10B981">
            {user?.balance?.amount !== undefined
              ? formatCurrency(user.balance.amount)
              : 'GC$ 0.00'}
          </Heading>
        </Box>
        <Text fontSize="sm" color="#BBBBBB">
          {`Você está prestes a se tornar parte de algo muito maior. Criamos este jogo não só para diversão, mas para reviver a sensação mágica de estar no controle de uma guitarra, onde quer que você esteja.`}
          <Button variant="link" colorScheme="yellow" onClick={openModal}>
            Ler mais
          </Button>
        </Text>
        <Button
          bg="#0e0e10"
          border="solid 1px #ffcc00"
          color="#ffcc00"
          borderRadius="full"
          boxSize="60px"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.3)"
          size="sm"
          w="full"
          colorScheme="green"
          onClick={onAddCreditsClick}>
          Comprar Guitar Coins
        </Button>
      </VStack>

      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent bg="#17161A" color="white" py={4}> {/* Adicionei py={4} no modal */}
          <ModalHeader>Mais sobre o nosso jogo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md">
              <strong>🪙O que são Guitar Coins?</strong>
              <br />
              Guitar Coins são mais do que simples fichas. Cada uma delas representa a oportunidade de mergulhar em uma experiência nostálgica e emocionante. Cada vez que você joga, você ajuda a manter esse sonho vivo.
              <br />
              <br />
              <hr />
              <br />
              <strong>👨‍💻Sobre a equipe</strong>
              <br />
              Somos uma equipe de desenvolvedores autônomos, movidos por uma paixão incontrolável. Sem investimento, sem grandes recursos, apenas nossa determinação de trazer o clássico jogo da guitarrinha para todos os cantos do mundo. Agora, você pode jogar de qualquer lugar: notebook, tablet ou celular. Não precisa mais de consoles caros!
              <br />
              <br />
              <hr />
              <br />
              <strong>🤗 Apoie nosso sonho</strong>
              <p>
                Ao comprar Guitar Coins, você não está apenas adquirindo fichas — você está nos ajudando a dar mais um passo em direção ao nosso sonho, e ao seu também. Com cada música jogada, você contribui para reviver as canções que marcaram nossa infância, e mais do que isso, permite que todos joguem sem a necessidade de consoles caros ou equipamentos sofisticados. Agora, tudo o que você precisa é de Guitar Coins e vontade de se divertir. <strong>Tudo que você precisa é qualquer celular e um 4G.</strong> Jogue, e nos ajude a continuar essa jornada!
              </p>


              <br />
              <br />
              <hr />
              <br />
              <strong>🆘Nos ajude a reviver o passado</strong>
              <br />
              Estamos trabalhando incansavelmente para trazer de volta aquelas músicas que você passava o final de semana inteiro jogando. Lembra? Estamos tão perto... sua ajuda pode fazer toda a diferença!
              <br />
              <br />
              <hr />
              <br />
              <strong>💀 AVISO AOS AMOSTRADINHOS 💀</strong>
              <br />
              <p>
                Ao acessar nosso site com qualquer intenção de inspecionar, clonar, derrubar, hackear ou prejudicar nossos sistemas ou equipe, você estará assumindo total responsabilidade pelas severas consequências legais e alternativas que inevitavelmente virão. Não se engane: tentativas de sabotagem não serão toleradas.
              </p>
              <br />
              <p>
                Nossa segurança cibernética não é apenas avançada — ela é implacável, como um predador silencioso. Cada passo que você der será vigiado, cada movimento seu será registrado. Nós vemos tudo. Mas o que fazemos com essas informações? Isso, você nunca saberá até ser tarde demais. Não há escapatória. O simples ato de tentar já te coloca em nosso radar. E quando agirmos, será rápido, silencioso e definitivo. A única pergunta que resta é: você terá coragem de descobrir o que acontece depois?
              </p>

              <br />
              <p>
                <p>
                  Nossas defesas vão além da tecnologia convencional. Somos como uma legião invisível, com as habilidades afiadas de quem vive nas sombras do ciberespaço. Inteligência artificial avançada monitora cada movimento seu, mas nossa verdadeira força está em nossa rede global de alianças  — grupos de segurança alternativa, conectados a hackers e especialistas em todos os cantos do mundo. Qualquer tentativa de ataque será detectada antes mesmo de começar, e quando decidirmos agir, será com uma precisão cirúrgica. Não importa onde você esteja, nossa resposta será devastadora, e não haverá retorno.
                </p>

              </p>
              <br />
              <p>
                Você deve entender uma coisa: suas ações não passam despercebidas, nem mesmo com VPN. Esses rastros sempre conduzem de volta ao responsável, e nós estaremos lá. Não importa onde você esteja ou como tente se esconder. Estamos prontos. E você?
              </p>
              <br />
              <p>
                Estamos deixando este aviso bem claro: esta é sua chance de se afastar e evitar problemas que nenhum dinheiro irá resolver (isso é uma promessa). Estamos sendo gentis ao avisar, porque, no fim das contas, as consequências serão inevitáveis e pesadas. A escolha é sua, mas não diga que não foi avisado.
              </p>

            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" mr={3} onClick={closeModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WelcomeBox;
