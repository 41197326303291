import React, { useState, useEffect } from 'react';
import { Text } from '@chakra-ui/react';

interface TimerProps {
  initialTime: number; // Tempo inicial em segundos
  onExpire: () => void; // Função chamada quando o tempo expira
}

const Timer: React.FC<TimerProps> = ({ initialTime, onExpire }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (timeLeft <= 0) {
      onExpire();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timer); // Limpa o intervalo quando o componente é desmontado
  }, [timeLeft, onExpire]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <Text fontSize="lg" color="white">
      Expira em: {formatTime(timeLeft)}
    </Text>
  );
};

export default Timer;
